<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Jornadas">
        <transition name="fade">
          <b-row v-if="items.length">
            <b-col class="dx-viewport">
              <DxDataGrid
                id="DaysTable"
                :data-source="items"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxExport :enabled="true" />
                <DxSelection
                  v-if="false"
                  :select-all-mode="allMode"
                  :show-check-boxes-mode="checkBoxesMode"
                  mode="multiple"
                />
                <DxGroupPanel
                  :visible="true"
                  emptyPanelText="Arrastre una columna aquí para agrupar"
                />
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxPager
                  :visible="true"
                  infoText="Página {0} de {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                  :allow-column-reordering="true"
                  :ref="gridRefName"
                />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxColumn
                  data-field="id"
                  caption="Cod"
                  alignment="center"
                  width="80"
                />
                <DxColumn
                  data-field="nombre"
                  caption="Jornada"
                  alignment="center"
                />
                <DxColumn
                  data-field="observacion"
                  caption="Observación"
                  alignment="center"
                />
                <DxColumn
                  data-field="color"
                  caption="Color"
                  alignment="center"
                  cell-template="templateColor"
                />
                <DxColumn
                  data-field="created_at"
                  caption="Fecha de creación"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                  :visible="false"
                />
                <DxColumn
                  caption=""
                  cell-template="templateButtons"
                  v-if="can('options-day')"
                  type="buttons"
                />
                <DxToolbar>
                  <DxItem location="before" template="createTemplate" />
                  <DxItem location="after" template="refreshTemplate" />
                  <DxItem name="columnChooserButton" />
                  <DxItem name="exportButton" />
                  <DxItem name="groupPanel" />
                  <DxItem name="searchPanel" />
                </DxToolbar>
                <template #createTemplate>
                  <DxButton
                    type="default"
                    styling-mode="contained"
                    icon="plus"
                    text="Agregar"
                    v-if="can('create-day')"
                    @click="$refs.CD.init()"
                  />
                </template>
                <template #refreshTemplate>
                  <DxButton icon="refresh" @click="refreshDataGrid()" />
                </template>
                <template #templateColor="{ data: cellData }">
                  <div
                    class="text-white"
                    :style="`background-color: ${cellData.data.color} !important;`"
                  >
                    {{ cellData.data.color | upperCase }}
                  </div>
                </template>
                <template #templateButtons="{ data: cellData }">
                  <div class="text-center">
                    <router-link
                      v-if="can('edit-day')"
                      :to="{
                        name: 'updateDay',
                        params: { id: cellData.data.id },
                      }"
                      class="btn btn-icon btn-circle btn-sm"
                    >
                      <b
                        ><i class="flaticon-edit-1 text-success opacity-70"></i
                      ></b>
                    </router-link>

                    <button
                      class="btn btn-icon btn-circle"
                      @click="deleteTurn(cellData.data.id)"
                    >
                      <b
                        ><i class="flaticon-delete-1 text-danger opacity-70"></i
                      ></b>
                    </button>
                  </div>
                </template>
              </DxDataGrid>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-card>
    </b-col>
    <CreateDay ref="CD" @loadDays="loadDays()" />
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}

#DaysTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxSelection,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
import can from "@/permission";
import { mapGetters } from "vuex";
import CreateDay from "./CreateDay.vue";
import { DxButton } from "devextreme-vue/button";
export default {
  name: "user-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxSelection,
    CreateDay,
    DxButton,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      allMode: "always",
      checkBoxesMode: "onClick",
      // : 'always',
      gridRefName: 'DataGridDay',
      selection: true,
      items: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
      selectedRowsData: [],
      prefix: "",
    };
  },
  mounted() {
    this.loadDays();
  },
  filters: {
    upperCase(values) {
      return values.toUpperCase();
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    can: can,
    refreshDataGrid() {
      this.loadDays();
    },
    loadDays() {
      this.items = [];
      axios
        .get(`tipo-jornadas`)
        .then((res) => {
          this.items = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteTurn(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar la jornada?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/tipo-jornadas/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadDays();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Document.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
