<template>
  <b-modal
    title="Crear Jornada"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Jornada</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.nombre"
            placeholder="DIA - NOCHE"
          />
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Observación</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.observacion"
            placeholder="Tu observación"
          />
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Color</label>
          <input
            type="color"
            class="form-control form-control-sm"
            v-model="form.color"
          />
        </div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="createDay()">
          <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      show: false,
      loading: false,
      form: {
        nombre: null,
        observacion: null,
        color: null,
      },
    };
  },
  methods: {
    init() {
      this.show = true;
      this.form = {
        nombre: null,
        observacion: null,
        color: null,
      };
    },
    createDay() {
      if (this.form.nombre && this.form.observacion && this.form.color) {
        axios
          .post(`/tipo-jornadas`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              toastr.success(res.data.information);
              this.$emit("loadDays", true);
              this.show = false;
            } else {
              toastr.info(res.data.information);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (!this.form.color) {
          toastr.info(`Escoja un color para la jornada que está creando`);
        }
        if (!this.form.nombre) {
          toastr.info(`El campo nombre jornada es obligatorio`);
        }
      }
    },
  },
};
</script>
